<template>
    <Bar class="custom-class" :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
        :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
    components: {
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 300
        },
        height: {
            type: Number,
            default: 147
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => ({})
        },
        plugins: {
            type: Array,
            default: () => []
        },
        dashboardData: {
            type: Object,  // Мы ожидаем объект
            default: () => ({})
        },
    },
    data() {
        return {
            labels: ['В зале', 'Доставка', 'На вынос'],
            counts: [
                this.dashboardData.inHouseReviewAmount,
                this.dashboardData.deliveryReviewAmount,
                this.dashboardData.selfPickUpReviewAmount
            ],
            barColors: ['#03A689', '#21E5C3', '#8BFEEA'],

            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        display: false
                    },
                    legend: {
                        display: false
                    },

                    tooltip: {
                        titleFont: {
                            family: 'Inter', // Шрифт для заголовка подсказок
                            size: 14, // Размер шрифта заголовка подсказок
                        },
                        titleColor: '#FFFFFF', // Цвет шрифта заголовка подсказок
                        bodyFont: {
                            family: 'Inter', // Шрифт для текста подсказок
                            size: 14, // Размер шрифта текста подсказок
                        },
                        callbacks: {
                            label: function (tooltipItem) {
                                // Название серии данных
                                let datasetLabel = tooltipItem.dataset.label || '';
                                // Значение с форматированием
                                let value = tooltipItem.raw.toLocaleString('ru-RU');
                                return `${datasetLabel}: ${value}`;
                            }
                        },
                        bodyColor: '#FFFFFF', // Цвет шрифта текста подсказок
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        //barPercentage: 0.2,        // Ширина столбца (чем меньше значение, тем уже столбец)
                        categoryPercentage: 0.8,        // Контролирует ширину столбца относительно доступного пространства
                        //categoryPercentage: 0.0, // Процент от ширины доступной категории (0.0 до 1.0)
                        //barThickness: 22,
                        ticks: {
                            // callback: function(value) {
                            //   return value.replace(/\s+/g, ' '); // Удаляет пробелы, если они не поддерживаются
                            // },
                            rotation: 50,
                            align: 'center',
                            font: {
                                family: 'Inter', // Шрифт для оси X
                                size: 13,
                                weight: 500, // Размер шрифта для оси X
                            },
                            color: '#323c4d' // Цвет шрифта для оси X
                        }
                    },
                    // y: {
                    //     stacked: true,
                    //     ticks: {
                    //         font: {
                    //             family: 'Inter', // Шрифт для оси Y
                    //             size: 12, // Размер шрифта для оси Y
                    //         },
                    //         color: '#000000' // Цвет шрифта для оси Y
                    //     }
                    // }
                }
            }
        }
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            // Форматируем дату в нужный формат (например, "дд.мм")
            return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;
        }
    },
    computed: {
        chartData() {
            // Создание массива данных для диаграммы
            const labels = ['В зале', 'Доставка', 'На вынос'];
            const data = [
                this.dashboardData.inHouseReviewAmount,
                this.dashboardData.deliveryReviewAmount,
                this.dashboardData.selfPickUpReviewAmount
            ];
            //barColors: ['#e41313', '#f2994a', '#00b800'],

            const backgroundColors = ['#03A689', '#21E5C3', '#8BFEEA'];

            // Фильтрация данных, меток и цветов, чтобы исключить нулевые значения
            const filteredData = data.map((value, index) => value > 0 ? value : 0);
            const filterdata1 = filteredData.filter(value => value !== 0);

            const filteredLabels = labels.filter((label, index) => data[index] > 0);
            const filteredBackgroundColors = backgroundColors.filter((color, index) => data[index] > 0); // Фильтрация цветов

            console.log(filterdata1);
            console.log(filteredData);
            console.log(filteredLabels);
            console.log(filteredBackgroundColors);

            return {
                labels: filteredLabels,
                datasets: [{
                    data: filterdata1, // Используем только отфильтрованные значения
                    backgroundColor: filteredBackgroundColors,
                    label: false,
                    barThickness: 22,

                    // Применяем отфильтрованные цвета
                    // borderWidth: 0
                }]
            };


            // return {
            //     labels: labels,

            //     datasets: [
            //         {
            //             label: 'В зале',
            //             backgroundColor: '#13414E',
            //             data: inHouseData,


            //         },
            //         {
            //             label: 'Доставка',
            //             backgroundColor: '#006D5A',
            //             data: deliveryData,
            //             font: 'Inter'
            //         },
            //         {
            //             label: 'На вынос',
            //             backgroundColor: '#14BF91',
            //             data: selfPickUpData,
            //             font: 'Inter'
            //         }
            //     ]
            // };
        }
    }
}
</script>


<style scoped>
.custom-class {
    width: 100%;
    /* 100% ширины родительского контейнера */
    height: 163px;
    /* 50% от высоты окна просмотра */
    position: relative;
    /* Для корректного рендеринга Chart.js */
}
</style>